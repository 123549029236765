import { useEffect, useState } from 'react'

export function useAsyncInitialize<T>(func: () => Promise<T>, deps: any = []) {
  const [state, setState] = useState<T | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    func()
      .then((data) => {
        setState(data)
        setLoading(false)
      })
      .catch((e) => {
        setError(e)
        setLoading(false)
      })
  }, deps)

  return { state, loading, error }
}
