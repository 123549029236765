import { useEffect, useState } from 'react'
import { storage } from '@/utils/storage'
import { useUpdateEffect } from 'ahooks'

export function useGameFreeDisabled(name: string) {
  const [freeDisabled, setFreeDisabled] = useState(false)
  // get free disabled from local storage
  useEffect(() => {
    const gameFreeDisabled = storage.getItem('gameFreeDisabled') || {}
    if (gameFreeDisabled[name]) {
      setFreeDisabled(true)
    }
  }, [])
  // save free disabled to local storage
  useUpdateEffect(() => {
    const gameFreeDisabled = storage.getItem('gameFreeDisabled') || {}
    if (!!gameFreeDisabled[name] !== freeDisabled) {
      storage.setItem('gameFreeDisabled', { ...gameFreeDisabled, [name]: freeDisabled })
    }
  }, [freeDisabled])
  return { freeDisabled, setFreeDisabled }
}
