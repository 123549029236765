import { FC, ReactNode } from 'react'
import { XImage } from '../common/XImage'
import { LoadingOutlined } from '@ant-design/icons'

const Btn: FC<{
  onClick: () => void
  disabled: boolean
  text: ReactNode
  className: string
  loading: boolean
  hiddenIcon?: boolean
}> = ({ onClick, disabled, text, className, loading, hiddenIcon = true }) => {
  return (
    <div
      className={`h-[40px] rounded-[20px] relative cursor-pointer ${className}`}
      onClick={() => {
        if (!disabled) {
          onClick()
        }
      }}
    >
      {hiddenIcon && (
        <XImage src="/images/my/invitebgicon.png" className="absolute left-[16px] top-[10px] z-[2] h-[4px]" />
      )}
      <div
        className={`absolute h-full w-full  rounded-[20px] z-[1] left-0 top-0 flex justify-center items-center text-[#070921] text-[16px] ${
          disabled ? 'text-[#1A1D1F] bg-[#3C3A5B] cursor-not-allowed' : 'bg-[#fce447] cursor-pointer'
        }`}
      >
        <span className="relative flex items-center select-none">
          {text}
          {loading && (
            <span className="absolute -right-6 -top-[2px]">
              <LoadingOutlined />
            </span>
          )}
        </span>
      </div>
      <div
        className={`absolute h-full w-full  rounded-[20px] z-0 left-0 top-[4px] ${
          disabled ? 'text-[#1A1D1F] bg-[#3C3A5B] cursor-not-allowed' : 'bg-[#FFBE10] cursor-pointer'
        }`}
      ></div>
    </div>
  )
}
export default Btn
