import type { NextPage } from 'next'

export type GetParamType<T> = T extends (...args: infer R) => any ? R : any

export type AsyncFuncRetType<T> = T extends () => Promise<infer R> ? R : any

export type MayBeArray<T> = T | T[]

export interface ResponsivePoint {
  lg: boolean
  md: boolean
  sm: boolean
  xl: boolean
  xs: boolean
}

export enum Locale {
  en = 'en',
  zh = 'zh',
  fr = 'fr',
  ko = 'ko',
  vi = 'vi',
  ja = 'ja',
  ru = 'ru',
}

export enum SolanaChain {
  Mainnet = -1,
  Devnet = -2,
  Testnet = -3,
}

export enum TonChain {
  Mainnet = -11,
  Devnet = -12,
}
