import { useTonConnectUI, useTonWallet } from '@tonconnect/ui-react'
import { SenderArguments } from '@ton/core'
import { useEffect, useState } from 'react'
import { publicKeyToAddress } from '@/utils/ton/utils'
import dayjs from 'dayjs'

export function useTonConnect() {
  const [tonConnectUI] = useTonConnectUI()
  const wallet = useTonWallet()
  const [ticker, setTicker] = useState(0)
  useEffect(() => {
    tonConnectUI.onStatusChange((res) => {
      setTicker(ticker + 1)
    })
    tonConnectUI.onModalStateChange(() => {
      setTicker(ticker + 1)
    })
  }, [tonConnectUI])
  return {
    sender: {
      send: async (args: SenderArguments) => {
        await tonConnectUI.sendTransaction({
          messages: [
            {
              address: args.to.toString(),
              amount: args.value.toString(),
              payload: args.body?.toBoc().toString('base64'),
            },
          ],
          validUntil: dayjs().add(5, 'minutes').unix(),
        })
      },
    },
    connected: tonConnectUI?.connected,
    account: tonConnectUI?.account,
    wallet: tonConnectUI?.wallet,
    bounceableAddress: publicKeyToAddress(tonConnectUI?.account?.publicKey || '', true),
    noneBounceableAddress: publicKeyToAddress(tonConnectUI?.account?.publicKey || '', false),
  }
}
