import { useAsyncInitialize } from '@/hooks/ton/useAsyncInitialize'
import { TonClient } from '@ton/ton'
import { getHttpEndpoint } from '@orbs-network/ton-access'
import { isTest } from '@/utils/env'

export function useTonClient() {
  return useAsyncInitialize(
    async () =>
      new TonClient({
        endpoint: await getHttpEndpoint({ network: isTest ? 'testnet' : 'mainnet' }),
      }),
  )
}
