import { isTest } from './env'
export const tokenList = [
  { address: '', name: 'Test1', symbol: 'PlatfomToken', decimals: 18, icon: '/images/token/token4.svg' },
  {
    address: '2RPZJMxmJCWzCvVgU11NddHXeyf2bDnRHgWum1g3WwkK',
    name: 'Token1',
    symbol: 'Token1',
    decimals: 18,
    icon: '/images/token/pepe.svg',
  },
  {
    address: 'DBTQkQtT2oJUqNo994Guof4jNbsZANxBNSsSESy6nUeY',
    name: 'Token2',
    symbol: 'Token2',
    decimals: 18,
    icon: '/images/token/dog.svg',
  },
]
export const nftTokenList = [
  {
    address: 'FnncKoa7aNvBsSEVf6KQrzijA8U8LKjvuy15Wv4Spgvm',
    name: 'NFT1',
    symbol: 'NFT1',
    decimals: 18,
    icon: '/images/token/dog2.svg',
  },
]
export const shareText = `Welcome to YOME - Earn with Every MEME! 🤪\r\n
Ready or not, the biggest Meme Gaming Platform powered by Cocos Studio on #TON is here!\r\n
  🎮 Web3 MEME Gaming Platform\n 🃏 A Platform for Acquiring MEME NFTs\n 💸A Re-staking Platform for MEME Tokens\r\n
Collect daily rewards just by playing! Huge Airdrop Await!`

export const tgChatGroup = isTest ? 'https://t.me/west_devoso' : 'https://t.me/YOMEGAME'
export const tgChannel = isTest ? 'https://t.me/west_devsos' : 'https://t.me/yome_news'
export const tgBot = isTest ? 'https://t.me/yome_test_bot' : 'https://t.me/YomeGame_Bot'
export const tgBotId = isTest ? '7288413440' : '7279059150'
export const tgMiniProgramUrl = isTest ? 'https://t.me/yome_test_bot/yome' : 'https://t.me/YomeGame_Bot/YomeGame'

export const inviteTgUrl = `https://t.me/share/url?url=${tgMiniProgramUrl}`

export const SociatyList = [
  { img: '/images/society/twitter.svg', link: 'https://x.com/YOMELabs' },
  { img: '/images/society/tel.svg', link: tgChatGroup },
  { img: '/images/society/github.svg', link: 'https://github.com/YomeFAB' },
  { img: '/images/society/doc.svg', link: 'https://docs.yome.finance' },
]

export function shuffle(arr: any[]) {
  for (let i = arr.length; i; i--) {
    // 产生一个随机位置
    let j = Math.floor(Math.random() * i)

    // 交换位置
    ;[arr[i - 1], arr[j]] = [arr[j], arr[i - 1]]
  }
  return arr
}
