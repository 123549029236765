import { getHttpService, postHttpService } from '@/utils/http/service'
import { Hex } from 'viem'
import { MintInfo, NFTType } from './game'

export enum ClaimType {
  TELEGRAM_IS_BOT_USER = 'TELEGRAM_IS_BOT_USER',
  TELEGRAM_CHAT_JOINED = 'TELEGRAM_CHAT_JOINED',
  TELEGRAM_CHANNEL_JOINED = 'TELEGRAM_CHANNEL_JOINED',
  TWITTER_FOLLOWED = 'TWITTER_FOLLOWED',
}
export enum DailyClaimType {
  DAILY_GAME = 'DAILY_GAME',
  DAILY_STIER_NFT = 'DAILY_STIER_NFT',
  DAILY_TWITTER_LIKE = 'DAILY_TWITTER_LIKE',
  DAILY_TWITTER_FORWARD = 'DAILY_TWITTER_FORWARD',
  DAILY_TWITTER_COMMENT = 'DAILY_TWITTER_COMMENT',
  DAILY_NORMAL_NFT = 'DAILY_NORMAL_NFT',
}
type TFragmentsItem = {
  gray_image_url: string
  image_url: string
  count: number
  index: number
}
export type TNFTItem = {
  prize_id: number
  already_owned: number
  pledged: number
  gray_image_url: string
  image_url: string
  name: 'PEPE' | 'Doge' | 'ShiBa' | 'WIF' | 'S-PEPE' | 'S-Doge' | 'S-ShiBa' | 'S-WIF'
  icon_url: string
  fragments: [TFragmentsItem, TFragmentsItem, TFragmentsItem, TFragmentsItem]
}
export type TNFTMergeDataItem = {
  merging_id: number // 重试需要改参数
  nft_prize_id: number // 重试需要改参数
  name: string // （PEPE,Doge,ShiBa,WIF,S-PEPE,S-Doge,S-ShiBa,S-WIF）
  image: string
  begin_timestamp: number //合成的开始时间，前端用来判断显示重试按钮
}
export const personalCenterService = {
  getTwitterRedirectUrl: getHttpService<string, { platform: 'WEB' | 'TELEGRAM' }>(
    '/v1/user/twitter/binding-redirect-url',
  ),
  getTwitterOauthResult: (state: string) => {
    return getHttpService<{
      twitter_id: string
      twitter_name: string
      twitter_user_name: string
      twitter_avatar: string
      followers_count: number
      is_valid: boolean
    }>(`/v1/twitter/bind-result/${state}`)
  },
  getDiscardRedirectUrl: getHttpService<string, {}>('/v1/user/discord/binding-redirect-url'),
  getTelegramRedirectUrl: getHttpService<string, {}>('/v1/user/telegram/binding-redirect-url'),
  telegramCallBack: getHttpService<
    string,
    {
      state: string
      code: number
      user_id: number
      user_name?: string
      first_name?: string
      last_name?: string
      avatar?: string
    }
  >('/v1/telegram/callback'),
  getDiscardOauthResult: (state: string) => {
    return getHttpService<{
      discord_id: string
      discord_name: string
      discord_user_name: string
      discord_avatar: string
    }>(`/v1/discord/bind-result/${state}`)
  },
  getTaskInfo: getHttpService<{
    allow_twitter_claim: boolean
    allow_discord_claim: boolean
    allow_played_games_claim: boolean
    allow_s_tier_nft_claim: boolean
    allow_telegram_claim: boolean
  }>(`/v1/user/task-info`),
  claim: postHttpService<
    string,
    { claim_type: 'TwitterBinding' | 'DiscordBinding' | 'DailyGame' | 'STierNFT' | 'TelegramBinding' }
  >(`/v1/user/task-claim`),
  getNFTList: getHttpService<TNFTItem[], {}>(`/v1/user/nft/list`),
  getNFTMergeData: postHttpService<MintInfo & { merging_id: number }, { nft_prize_id: number; merging_id?: number }>(
    `/v1/user/nft/merge`,
  ),
  getMergingList: getHttpService<
    {
      list: TNFTMergeDataItem[]
    },
    {}
  >(`/v1/user/merging/list`),
  getBasicInfo: getHttpService<
    {
      yome_bot: boolean
      join_new_channel: boolean
      join_chat_group: boolean
      follow_on_twitter: boolean
      telegram_bound: boolean
      twitter_bound: boolean
      allow_yome_bot_claim: boolean
      allow_join_new_channel_claim: boolean
      allow_join_chat_group_claim: boolean
      allow_follow_on_twitter_claim: boolean
      yome_bot_claimed: boolean
      join_new_channel_claimed: boolean
      join_chat_group_claimed: boolean
      follow_on_twitter_claimed: boolean
    },
    {}
  >(`/v1/user/task/basic/info`),
  claimBasicPoints: postHttpService<{}, { claim_type: ClaimType }>(`/v1/user/task/basic/claim`),
  getDailyTaskInfo: getHttpService<
    {
      daily_game: boolean
      s_tier_nft: boolean
      allow_daily_game_claim: boolean
      allow_s_tier_nft_claim: boolean
      allow_daily_twitter_like_claim: boolean
      allow_daily_twitter_forward_claim: boolean
      allow_daily_twitter_comment_claim: boolean
      normal_nft: boolean
      allow_normal_nft_claim: boolean
    },
    {}
  >(`/v1/user/task/daily/info`),
  claimDailyPoints: postHttpService<{ daily_game: boolean; s_tier_nft: boolean }, { claim_type: DailyClaimType }>(
    `/v1/user/task/daily/claim`,
  ),
  getInvitedInfo: getHttpService<TInviteInfo, {}>(`/v1/user/task/invited/info`),
  getBuffCardList: getHttpService<TBuffCardItem[], {}>(`/v1/boost-card/list`),
  openBlinBox: postHttpService<
    {
      id: number
      user_id: number
      level: string
      percentage: number
      status: string
      expiration_time: number
      create_time: number
      update_time: number
    },
    {}
  >(`/v1/boost-card/mystery_box/open`),
  useBuffCard: postHttpService<{}, { card_level: TBuffCardItem['level'] }>(`/v1/boost-card/use`),
  postOnlineDuring: postHttpService<number, {}>(`/v1/user/task/daily/online`),
  getDailyTwitterUrlList: getHttpService<
    {
      comment_count: number
      create_time: number
      external_tweet_id: string
      external_tweet_link: string
      id: number
      is_deleted: boolean
      last_sync_time: number
      like_count: number
      ref_retweet_count: number
      retweet_count: number
      task_type: 'TWEET_DAILY_LIKE' | 'TWEET_DAILY_FORWARD' | 'TWEET_DAILY_COMMENT'
      update_time: number
      view_count: number
    }[],
    {}
  >(`/v1/user/task/twitter/list`),
  getOrderStatus: getHttpService<
    {
      order_id: string
      comment: string
      amount: string
      purchase_count: number
      status: 'PENDING' | 'SUCCESS' | 'FAILED'
    },
    { order_id: string }
  >(`/v1/order/query`),
  redemptionCode: postHttpService<{ points: number; nft: MintInfo }, { code: string }>(`/v1/user/airdrop/redeem`),
  airdropCheck: getHttpService<{ points: number; nft: 'normal' }, { code: string }>(`/v1/user/airdrop/check`),
  getTwitterTaskInfo: getHttpService<
    {
      tweet_like: {
        tweet_id: number
        link: string
        claimed: boolean
      }
      tweet_comment: {
        tweet_id: number
        link: string
        claimed: boolean
      }
      tweet_forward: {
        tweet_id: number
        link: string
        claimed: boolean
      }
    },
    {}
  >(`/v1/user/task/twitter/info`),
  twitterClaim: postHttpService<{}, { tweet_id: number }>(`/v1/user/task/twitter/claim`),
}
export type TBuffCardItem = {
  level: 'S' | 'SS' | 'SSS' | 'SSSS' | 'SSSSS'
  percentage: number
  count: number
  using: boolean
}
export type TInviteInfo = {
  invited_code: string
  invited_root_code: string
  invited_count: number
  invited_users: {
    id: number
    address: string
    avatar: string
    nick_name: string
    create_time: number
    update_time: number
    is_white_list: boolean
  }[]
  invited_points: number
}
