'use client'

import { AnchorProvider } from '@coral-xyz/anchor'
import { WalletError } from '@solana/wallet-adapter-base'
import {
  AnchorWallet,
  ConnectionProvider,
  useConnection,
  useWallet,
  WalletProvider,
} from '@solana/wallet-adapter-react'
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui'
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare'
import { ReactNode, useCallback, useMemo } from 'react'
import { toWalletAdapterNetwork, useCluster } from '../views/sol/cluster-data-access'
import { isTest } from '@/utils/env'

require('@solana/wallet-adapter-react-ui/styles.css')

export function SolanaProvider({ children }: { children: ReactNode }) {
  const { cluster } = useCluster()
  const endpoint = useMemo(() => cluster.endpoint, [cluster])
  const wallets = useMemo(
    () => [
      new SolflareWalletAdapter({
        network: toWalletAdapterNetwork(cluster.network),
      }),
    ],
    [cluster],
  )

  const onError = useCallback((error: WalletError) => {
    console.error(error)
  }, [])

  return (
    <ConnectionProvider
      endpoint={endpoint}
      config={
        isTest
          ? { commitment: 'confirmed' }
          : {
              commitment: 'confirmed',
              wsEndpoint:
                'wss://rpc.ankr.com/solana/ws/3b9f7f22956063805df11b6bacd94b7e7e5548647b25f26f8d21a12642dbfea0',
              confirmTransactionInitialTimeout: 60000,
            }
      }
    >
      <WalletProvider wallets={wallets} onError={onError} autoConnect={true}>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  )
}

export function useAnchorProvider() {
  const { connection } = useConnection()
  const wallet = useWallet()

  return new AnchorProvider(connection, wallet as AnchorWallet, {
    commitment: 'confirmed',
  })
}

export const useSolWallet = useWallet
