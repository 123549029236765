import { FC, useEffect, useState, memo } from 'react'
import { XImage } from '../common/XImage'
import { gsap } from 'gsap'
import { useTranslations } from 'next-intl'

const RedPkgAnimation: FC<{
  data: { src: string; width: number; points: number }
  index: number
  mainRef: any
  onCheckedPoints: (points: number) => void
}> = ({ data, index, mainRef, onCheckedPoints }) => {
  const t = useTranslations('redPkgRain')
  const [ramdomValue, setRamdomValue] = useState(-1)
  useEffect(() => {
    setRamdomValue(Math.random())
  }, [])
  const [maxLeft, setMaxLeft] = useState(0)
  useEffect(() => {
    setMaxLeft(document.body.offsetWidth)
  }, [])
  useEffect(() => {
    if (!!maxLeft) {
      gsap.context((self: gsap.Context) => {
        const transication = self.selector?.(`#img-${index}`)
        const animation = gsap.fromTo(
          transication,
          {
            y: 0,
            duration: 4.5,
            repeat: -1,
            yoyo: false,
            ease: 'power1.inOut',
            // ease: 'none',
            // ease: 'elastic.out(1,0.3)',
          },
          {
            y: window.innerHeight / 0.8,
            duration: 4.5,
            //   ease: 'Circ.easeOut',
            repeat: -1,
            yoyo: false, //
            ease: 'power1.inOut',
            // ease: 'none',
            // ease: 'elastic.out(1,0.3)',
          },
        )
        animation.delay(index / 5)
      }, mainRef)
    }
  }, [mainRef, maxLeft])
  const [isChecked, setIsChecked] = useState(false)
  return (
    <>
      {maxLeft && ramdomValue > 0 && (
        <span
          className={`fixed top-[-120px] cursor-pointer pt-[30px]`}
          id={`img-${index}`}
          style={{
            left: `${ramdomValue * (maxLeft - 60)}px`,
          }}
          onClick={(e) => {
            e.stopPropagation()
            onCheckedPoints(data.points)
            if (isChecked) return
            setIsChecked(true)
            var tl = gsap.timeline()
            tl.to(`#img-${index}`, { alpha: 0, duration: 0.5 })
            tl.to(`#img-${index}`, { zIndex: -1, duration: 0.5 })
            // gsap.fromTo(`#img-${index}`, { alpha: 1, duration: 0.5 }, { alpha: 0, duration: 0.5 })
            gsap.fromTo(`#tip-${index}`, { alpha: 1, y: 0, duration: 1 }, { alpha: 0, y: -150, duration: 1 })
          }}
        >
          <XImage
            src={data.src}
            style={{
              width: `${data.width}px`,
              left: `${ramdomValue * (maxLeft - 60)}px`,
            }}
          />
          <div
            id={`tip-${index}`}
            className="absolute left-[50%] translate-x-[-50%] top-[-16px] text-[12px] text-[gray] opacity-0 w-[80px] text-center"
          >
            + {data.points} {t('Points')}
          </div>
        </span>
      )}
    </>
  )
}
// export default memo(RedPkgAnimation)
export default RedPkgAnimation
