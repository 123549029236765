import { getHttpService, postHttpService } from '@/utils/http/service'

export type TCheckCodeRes = {
  public_key: string
  history_id: number
}
export const redPkgRainService = {
  checkRedPkgCode: postHttpService<TCheckCodeRes, {}>('/v1/game/lucky-code/start'),
  queryRedPkgStatus: getHttpService<{ id: 0; start_time: 0; end_time: 0 }, {}>('/v1/game/lucky-code/query'),
  submitRedPkgPoints: postHttpService<
    {
      history_id: number
      points: number
      coins?: number
      coin_symbol?: string
      game_data: string
    },
    any
  >('/v1/game/lucky-code/confirm'),
}
