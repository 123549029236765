import { MintInfo, NFTType } from '@/utils/http/services/game'
import { useTonClient } from '@/hooks/ton/useTonClient'
import { Address, beginCell, toNano } from '@ton/core'
import { YomeNft } from '@/utils/ton/build/YomeNft/tact_YomeNft'
import { useTonConnect } from '@/hooks/ton/useTonConnect'
import { toastError } from '@/context/MessageContext'
import { getErrorMsg, sleep } from '@/utils'
import { orderService } from '@/utils/http/services/order'

const NormalNft = 1n
const SuperNft = 2n

export default function useTonMint() {
  const { state: client } = useTonClient()
  const { sender, account } = useTonConnect()
  const mintFragment = async (mintFragmentInfo: MintInfo) => {
    if (!client || !account) return false
    const yomeNft = client.open(YomeNft.fromAddress(Address.parse(mintFragmentInfo.contract_address)))
    await yomeNft.send(
      sender,
      {
        value: toNano(mintFragmentInfo.payment_amount + 0.05),
      },
      {
        $$type: 'FragmentNft',
        signature: beginCell().storeBuffer(Buffer.from(mintFragmentInfo.signature, 'base64')).endCell(),
        toUser: Address.parse(account.address),
        orderId: BigInt(mintFragmentInfo.order_id),
        signatureType: BigInt(mintFragmentInfo.signature_type),
        seqno: BigInt(mintFragmentInfo.nonce),
      },
    )
    const status = await waitForMintStatus(mintFragmentInfo.order_id)
    return status === 'SUCCESS'
  }
  const synthesisNFT = async (synthesisNftInfo: MintInfo) => {
    if (!client || !account) return false
    try {
      const yomeNft = client.open(YomeNft.fromAddress(Address.parse(synthesisNftInfo.contract_address)))
      await yomeNft.send(
        sender,
        {
          value: toNano(synthesisNftInfo.payment_amount + 0.09),
        },
        {
          $$type: 'SynthesisNftBefore',
          signature: beginCell().storeBuffer(Buffer.from(synthesisNftInfo.signature, 'base64')).endCell(),
          toUser: Address.parse(account.address),
          orderId: BigInt(synthesisNftInfo.order_id),
          signatureType: BigInt(synthesisNftInfo.signature_type),
          seqno: BigInt(synthesisNftInfo.nonce),
          nftCollection: Address.parse(synthesisNftInfo.sign_param.nft_collection_address),
          nftType: BigInt(synthesisNftInfo.nft_type === NFTType.Super ? SuperNft : NormalNft),
          nftItemIndex: BigInt(synthesisNftInfo.sign_param.nft_item_index),
          nftContent: synthesisNftInfo.sign_param.nft_item_content_url,
        },
      )
      const status = await waitForMintStatus(synthesisNftInfo.order_id)
      return status === 'SUCCESS'
    } catch (error) {
      toastError(getErrorMsg(error))
      return false
    }
  }
  return { mintFragment, synthesisNFT }
}

async function waitForMintStatus(orderId: number) {
  while (true) {
    const { status } = await orderService.queryMint({ order_id: orderId })
    if (status === 'PENDING') {
      await sleep(3000)
      continue
    }
    return status
  }
}
