'use client'
import React, { useState, useEffect, FC, useRef } from 'react'
import './index.css'
import { useInterval } from 'ahooks'
import RedPkgAnimation from './redPkgAnimation'
import { shuffle } from '@/utils/constants'
import { useTranslations } from 'next-intl'

let jiaoArr = Array(8).fill({ src: '/images/redPkg/jiao.png', type: 'jiao', width: 45, points: 2 })
let yomeArr = Array(15).fill({ src: '/images/redPkg/yome.png', type: 'yome', width: 50, points: 3 })
let pkgArr = Array(1).fill({ src: '/images/redPkg/pkg.png', type: 'pkg', width: 103, points: 18 })
const imgArr = shuffle([...jiaoArr, ...pkgArr, ...yomeArr])

const RedPkgRain: FC<{ onResult: (countPoints: number) => void; onClick: () => void }> = ({ onResult, onClick }) => {
  const t = useTranslations('redPkgRain')
  const ref = useRef<HTMLDivElement | null>(null)
  const [countPoints, setCountPoints] = useState(0)
  const [maxLeft, setMaxLeft] = useState(0)
  const [timeCount, setTimeCount] = useState(10)

  const clearInterval = useInterval(() => {
    setTimeCount(timeCount - 1)
  }, 1000)
  useEffect(() => {
    if (timeCount <= 0) {
      clearInterval()
      onResult(countPoints > 79 ? 79 : countPoints)
    }
  }, [timeCount])
  useEffect(() => {
    setMaxLeft(document.body.offsetWidth)
  }, [])
  return (
    <div className="" ref={ref} onClick={onClick}>
      {!!maxLeft && (
        <>
          <div className="flex gap-x-[10px] text-[14px] justify-center">
            <span>{t('Remainder')}</span>
            <span className="text-[#FEE226]">
              {timeCount} {t('S')}
            </span>
          </div>
          {imgArr.map((item, index) => {
            return (
              <RedPkgAnimation
                data={item}
                index={index}
                key={index}
                mainRef={ref}
                onCheckedPoints={(points) => {
                  setCountPoints(countPoints + points)
                }}
              />
            )
          })}
        </>
      )}
    </div>
  )
}
export default RedPkgRain
