import { GameOverInfo } from '@/utils/http/services/game'
import { useEffect } from 'react'
import { storage } from '@/utils/storage'
import { useRouter } from '@/navigation'
import { usePathname } from 'next/navigation'
import { useDebounceEffect, useRequest } from 'ahooks'
import { TCheckCodeRes } from '@/utils/http/services/redPkgRain'
import dayjs from 'dayjs'
import { useIsTMARef } from '@/hooks/useIsTMARef'
import { isTMA } from '@telegram-apps/sdk'

export enum RevertSceneType {
  ConnectWallet,
  RewardConnect,
  ThreeFoldConnect,
}

export enum ConnectScene {
  Header, // header触发连钱包场景
  NewBieModal, //airdrop新手礼包弹窗触发的连钱包
  MyNFTs, // 我的NFT页面触发的连钱包
  MyPurchase, // 我的购买触发的连钱包
  GamePurchase, // 游戏购买触发的连钱包
  Pledge, // 质押触发的连钱包
}

//连接钱包场景数据
export interface ConnectSceneData {
  scene: ConnectScene
}

export interface RewardConnectSceneData {
  score: number
  gameOverInfo: GameOverInfo
}

export interface FortuneCodeSceneData {
  countPoints: number
  checkedCodeData?: TCheckCodeRes
}

export interface SceneDataMap {
  [RevertSceneType.ConnectWallet]: ConnectSceneData
  [RevertSceneType.RewardConnect]: RewardConnectSceneData
  [RevertSceneType.ThreeFoldConnect]: FortuneCodeSceneData
}

export function useRevertScene<T extends RevertSceneType, K = SceneDataMap[T]>(
  type: T,
  callback: (url: string, data: K) => void,
) {
  const { data: isTelApp } = useRequest(isTMA)
  useDebounceEffect(() => {
    if (!isTelApp) return
    const sceneData = storage.getItem('sceneData')
    if (sceneData && sceneData.type === type) {
      if (sceneData.timestamp < dayjs().subtract(5, 'minute').unix()) {
        storage.remove('sceneData')
        return
      }
      callback(sceneData.url, sceneData.data as K)
      storage.remove('sceneData')
    }
  }, [isTelApp])
}

export function saveSceneData<T extends RevertSceneType, K = SceneDataMap[T]>(type: T, data: K) {
  // if (navigator.userAgent.match(/windows/i)) {
  // Windows系统才存储
  storage.setItem('sceneData', {
    type,
    url: location.pathname,
    timestamp: dayjs().unix(),
    data,
  })
  // }
}
