'use client'

import { ModalComponent } from '@/context/ModalContext'
import Modal from '@/views/modal/Modal'
import { useTranslations } from 'next-intl'
import { XImage } from '@/views/common/XImage'
import { Button } from '@/views/common/Button'
import { GameOverInfo, gameService, NFTType } from '@/utils/http/services/game'
import { useEffect, useState } from 'react'
import { toastError, toastSuccess } from '@/context/MessageContext'
import { useWalletModal } from '@solana/wallet-adapter-react-ui'
import { useUserContext } from '@/context/UserContext'
import useTonMint from '@/hooks/ton/useTonMint'
import { useTonConnectUI } from '@tonconnect/ui-react'
import { ChainType } from '@/context/ChainTypeContext'
import { useIsTMARef } from '@/hooks/useIsTMARef'
import { globalEvent, GlobalEventName } from '@/utils/event'
import { RevertSceneType, saveSceneData } from '@/hooks/useRevertScene'
import { storage } from '@/utils/storage'
import { useRequest } from 'ahooks'
import { isTMA } from '@telegram-apps/sdk'
import useTonTokenBalance from '@/hooks/ton/useTonTokenBalance'
import R from '@/utils/http/request'
import { personalCenterService } from '@/utils/http/services/personal-center'
import { inviteTgUrl, shareText } from '@/utils/constants'

export const HaveRewardModal: ModalComponent<'reset' | '', { info: GameOverInfo; score: number }> = (props) => {
  const { isLogin, signIn, userInfo: tgUserInfo } = useUserContext()
  const { data: invitedInfo, refresh: refreshInvitedInfo } = useRequest(R(personalCenterService.getInvitedInfo, {}), {
    ready: isLogin,
    refreshDeps: [isLogin],
  })

  const t = useTranslations('play')
  const t1 = useTranslations('common')
  const [loading, setLoading] = useState(false)
  // const { mintFragment, synthesisNFT } = useMintProgram()
  // const wallet = useSolWallet()
  const { mintFragment, synthesisNFT } = useTonMint()
  const { setVisible } = useWalletModal()
  const [ton] = useTonConnectUI()
  const { data: isInTma } = useRequest(isTMA)
  const { getBalance } = useTonTokenBalance()

  useEffect(() => {
    const handler = () => {
      console.log('new user login closed')
      props.onClose()
    }
    globalEvent.on(GlobalEventName.NewUserLogin, handler)
    return () => {
      globalEvent.off(GlobalEventName.NewUserLogin, handler)
    }
  }, [])
  const isTMARef = useIsTMARef()
  useEffect(() => {
    if (props.isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [props.isOpen])
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      // showClose
      className={'h-fit w-[100%] lg:w-90  bg-transparent items-baseline'}
      style={{ overflowY: 'hidden', overflowX: 'hidden' }}
    >
      <div
        className="w-[100%] flex flex-col items-center ml-[50%] translate-x-[-50%] pb-5 px-5 no-scrollbar"
        style={{ overflowY: 'scroll', height: '100vh' }}
      >
        <div className="flex justify-center items-center pt-8">
          <p
            className={
              'w-50 h-10 rounded-full bg-black080921   pl-9 text-left text-base font-jua text-white flex justify-start items-center gap-x-7'
            }
          >
            <span>{t('result')}</span> <span>{t('score', { score: props.score })}</span>
          </p>
        </div>
        <div className="bg-[#080921] px-8 py-6.5 rounded-[40px] mt-[14px] relative">
          <div className="absolute right-[14px] top-[12px]">
            <XImage src="/images/icon/close_sm.svg" className="cursor-pointer" onClick={props.onClose} />
          </div>
          <h3 className={'uppercase text-[24px] lg:text-3xl text-white font-luck text-center break-all'}>
            {t('Congratulations')}
          </h3>
          <p className={'mt-4 text-gray808C9C leading-5 text-[14px] lg:text-[16px]'}>
            {props.info.nft_prize_fragment
              ? t(props.info.nft_prize_type === NFTType.Super ? 'receive_s_nft_fragment' : 'receive_nft_fragment', {
                  name: props.info.nft_prize_name,
                })
              : t(props.info.nft_prize_type === NFTType.Super ? 'receive_s_nft_complete' : 'receive_nft_complete', {
                  name: props.info.nft_prize_name,
                })}
          </p>
          <XImage src={props.info.nft_prize_image_url} className={'w-50 h-50 mt-5.5 mx-auto block'} />
          <div className={'flex justify-center items-center mt-7 gap-x-1.5'}>
            {ton.connected ? (
              <Button
                className={'w-41 px-8'}
                loading={loading}
                onClick={async () => {
                  // if (!wallet.connected || !isLogin) {
                  //   setVisible(true)
                  //   return
                  // }
                  setLoading(true)
                  try {
                    const mintInfo = await gameService.receiveConfirm({ nft_raffle_id: props.info.nft_raffle_id })
                    const tokenAmount = await getBalance()
                    if ((tokenAmount.uiAmount || 0) < mintInfo.payment_amount) {
                      toastError(t1('insufficent'))
                      return
                    }
                    let result = false
                    if (props.info.nft_prize_fragment) {
                      result = await mintFragment(mintInfo)
                    } else {
                      result = await synthesisNFT(mintInfo)
                    }
                    result && toastSuccess(t('receive_success'))
                    props.resolve('')
                  } catch (e) {
                    toastError(e)
                  } finally {
                    setLoading(false)
                  }
                }}
              >
                {t('receive')}
              </Button>
            ) : (
              <Button
                className={'px-8'}
                onClick={async () => {
                  if (isTMARef.current) {
                    window.enableLoginConfirm = true
                    saveSceneData(RevertSceneType.RewardConnect, { gameOverInfo: props.info, score: props.score })
                  }
                  signIn(ChainType.Ton)
                }}
              >
                {t1('connect')}
              </Button>
            )}
            {/*<Button*/}
            {/*  className={'w-10'}*/}
            {/*  onClick={() => {*/}
            {/*    props.resolve('reset')*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <XImage src={Images.GAME.RESET_SVG} className={'w-5 h-5'} />*/}
            {/*</Button>*/}
          </div>
        </div>
        {/* {!props.info.nft_prize_fragment && props.info.nft_prize_type === NFTType.Super && (
          <p
            className={'text-sm text-gray808C9C leading-[18px] absolute left-0 w-full text-center break-all -bottom-14'}
          >
            {t('pay_mint_tips')}
          </p>
        )} */}
        <div className="flex justify-center items-center">
          <div
            className={
              'text-sm text-gray808C9C leading-[18px]   w-[80%]  break-all  flex gap-x-[8px] items-center mt-[12px]'
            }
          >
            <span className="flex-1">{t('Your chances for a complete S-tier upgrade leveled up!')}</span>
            <div
              className="flex justify-center items-center w-[40px] h-[40px] rounded-full bg-[#A0EED6] cursor-pointer"
              onClick={() => {
                if (invitedInfo?.invited_code || tgUserInfo?.code) {
                  window.open(
                    `${inviteTgUrl}?startapp=${tgUserInfo?.code || invitedInfo?.invited_code || ''}${
                      tgUserInfo?.root_code || invitedInfo?.invited_root_code
                        ? '--' + (tgUserInfo?.root_code || invitedInfo?.invited_root_code)
                        : ''
                    }&text=${encodeURIComponent(shareText)}`,
                  )
                }
              }}
            >
              <XImage src="/images/game/shareicon.png" width={20} />
            </div>
          </div>
        </div>
        {!props.info.nft_prize_fragment && props.info.nft_prize_type === NFTType.Super && (
          <div className="flex justify-center items-center">
            <p className={'text-sm text-gray808C9C leading-[18px]  w-[80%]  break-all mt-[10px]  '}>
              {t('pay_mint_tips')}
            </p>
          </div>
        )}
      </div>
    </Modal>
  )
}
