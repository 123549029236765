'use client'

import { ModalComponent } from '@/context/ModalContext'
import Modal from '@/views/modal/Modal'
import { useTranslations } from 'next-intl'
import { XImage } from '@/views/common/XImage'
import { Images } from '@/utils/images'
import { Button } from '@/views/common/Button'

export const NoRewardModal: ModalComponent<{}, {}> = (props) => {
  const t = useTranslations('play')
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      showClose
      className={'h-fit w-[80%] lg:w-90 pt-10 px-13 pb-6.5'}
    >
      <h3 className={'uppercase text-[24px] lg:text-3xl text-white font-luck text-center'}>{t('Sorry')}</h3>
      <p className={'mt-5 text-gray808C9C leading-5 text-[14px] lg:text-[16px]'}>{t('no_rewards_title')}</p>
      <XImage src={Images.GAME.NO_REWARD_SVG} className={'w-28 h-28 mt-15 mx-auto block'} />
      <Button className={'w-41 block mx-auto mt-24'} onClick={() => props.onClose()}>
        OK
      </Button>
    </Modal>
  )
}
