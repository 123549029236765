import { isServer } from './env'
import { ConnectSceneData, FortuneCodeSceneData, RevertSceneType, RewardConnectSceneData } from '@/hooks/useRevertScene'
import { MintInfo } from '@/utils/http/services/game'

export interface StorageData {
  redPkgRainDate?: number
  inviteCode?: string
  inviteRootCode?: string
  gameFreeDisabled?: {
    [key: string]: boolean
  }
  synthesisNFTFailedList?: number[]
  synthesisNFTingList?: number[]
  enableLoginConfirm?: boolean
  telegram_id?: number
  sceneData?: {
    type: RevertSceneType
    url: string
    timestamp: number
    data: RewardConnectSceneData | FortuneCodeSceneData | ConnectSceneData
  }
  redemptionData?: {
    [key: string]: MintInfo
  }
}

type KEY = keyof StorageData

function getItem<K extends keyof StorageData, R = StorageData[K]>(key: K): R | undefined {
  if (isServer || !window.localStorage.getItem(key)) return undefined
  return JSON.parse(window.localStorage.getItem(key) as string) as R
}

function setItem<K extends keyof StorageData, V = StorageData[K]>(key: KEY, value: V) {
  if (!isServer) {
    window.localStorage.setItem(key, JSON.stringify(value))
  }
}

function remove(key: KEY) {
  if (!isServer) {
    window.localStorage.removeItem(key)
  }
}

function clear() {
  if (!isServer) {
    window.localStorage.clear()
  }
}

export const storage = {
  getItem,
  setItem,
  remove,
  clear,
}
