import { XImage } from '@/views/common/XImage'
import React, { FC } from 'react'
import './index.css'
import { useTranslations } from 'next-intl'

const NoPointsResult: FC<{
  onClose: () => void
}> = ({ onClose }) => {
  const t = useTranslations('redPkgRain')
  return (
    <div>
      <div className="text-[#A0EED6] text-[26px] border-t border-b border-[#2F7D81] border-solid flex justify-center items-center gap-x-[11px] py-[12px]">
        <XImage src="/images/redPkg/clock.png" width={35} />
        <span>{t('Time out')}</span>
      </div>
      <div className="flex justify-end mt-[64px] pr-[10px]">
        <XImage src="/images/icon/close_sm.svg" className="cursor-pointer" onClick={onClose} />
      </div>
      <div className="text-[#FFF] text-[16px] text-center">{t('Sorry')}</div>
      <div className="flex justify-center">
        <XImage src="/images/redPkg/noPointsResult.png" className="w-[132px] mt-[52px]" />
      </div>
      <div className="flex justify-center mt-[53px]">{t("You didn't win any prizes")}</div>
      <div className="flex justify-center mt-[70px]">
        <div
          className={`w-[80%] h-[40px] rounded-[20px] relative cursor-pointer`}
          onClick={() => {
            onClose()
          }}
        >
          {true && (
            <XImage src="/images/my/invitebgicon.png" className="absolute left-[16px] top-[10px] z-[2] h-[4px]" />
          )}
          <div
            className={`absolute h-full w-full  rounded-[20px] z-[1] left-0 top-0 flex justify-center items-center text-[#070921] text-[16px] ${
              false ? 'text-[#1A1D1F] bg-[#3C3A5B] cursor-not-allowed' : 'bg-[#fce447] cursor-pointer'
            }`}
          >
            {t('Confirm')}
          </div>
          <div
            className={`absolute h-full w-full  rounded-[20px] z-0 left-0 top-[4px] ${
              false ? 'text-[#1A1D1F] bg-[#3C3A5B] cursor-not-allowed' : 'bg-[#FFBE10] cursor-pointer'
            }`}
          ></div>
        </div>
      </div>
    </div>
  )
}
export default NoPointsResult
