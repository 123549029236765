import { useEffect, useState } from 'react'
import { TokenAmount } from '@solana/web3.js'
import { useTonConnect } from '@/hooks/ton/useTonConnect'
import { useTonClient } from '@/hooks/ton/useTonClient'
import { Address, fromNano } from '@ton/core'
import { JettonMinter } from '@/utils/ton/wrappers/JettonMinter'
import { JettonWallet } from '@/utils/ton/wrappers/JettonWallet'
import { formatUnits } from 'viem'

export default function useTonTokenBalance(
  { decimals, jettonAddress }: { decimals: number; jettonAddress?: string } = { decimals: 9 },
) {
  const { account } = useTonConnect()
  const { state: client } = useTonClient()
  const [loading, setLoading] = useState(false)
  const [tokenAmount, setTokenAmount] = useState<TokenAmount>({
    amount: '0',
    decimals: decimals,
    uiAmount: 0,
    uiAmountString: '0',
  })
  const [tick, setTick] = useState(0)

  const getBalance = async () => {
    let balance = 0n
    if (account?.address && client) {
      try {
        setLoading(true)
        if (jettonAddress) {
          console.log('jettonAddress', jettonAddress)
          const jettonMinter = client.open(JettonMinter.createFromAddress(Address.parse(jettonAddress)))
          const jettonWalletAddress = await jettonMinter.getWalletAddress(Address.parse(account.address))
          const jettonWallet = client.open(JettonWallet.createFromAddress(jettonWalletAddress))
          balance = await jettonWallet.getJettonBalance()
        } else {
          balance = await client.getBalance(Address.parse(account.address))
        }
      } catch (e) {
      } finally {
        setLoading(false)
      }
    }
    const _tokenAmount = {
      amount: balance + '',
      decimals,
      uiAmount: +formatUnits(balance, decimals),
      uiAmountString: formatUnits(balance, decimals),
    }
    setTokenAmount(_tokenAmount)
    return _tokenAmount
  }

  useEffect(() => {
    getBalance()
  }, [tick, client, jettonAddress, decimals])

  const refresh = () => {
    setTick((tick) => tick + 1)
  }

  return { tokenAmount, loading, refresh, getBalance }
}
