'use client'

import { ModalComponent } from '@/context/ModalContext'
import Modal from '@/views/modal/Modal'
import { Button } from '@/views/common/Button'
import { useTranslations } from 'next-intl'

export const ConfirmModal: ModalComponent<
  boolean,
  {
    title: string
    description: string
    confirmText?: string
    cancelText?: string
  }
> = (props) => {
  const t = useTranslations('common')
  return (
    <Modal
      className={'w-75 h-fit flex flex-col justify-start items-center pt-12 pb-6 px-6'}
      isOpen={props.isOpen}
      showClose
      onClose={props.onClose}
    >
      <h3 className={'font-luck text-white text-center text-2xl'}>{props.title}</h3>
      <p className={'mt-4 text-center text-gray808C9C text-sm'}>{props.description}</p>
      <div className={'mt-10 flex justify-center items-center gap-x-2'}>
        <Button className={'h-9 text-base px-8'} onClick={() => props.resolve(true)}>
          {props.confirmText || t('btn_confirm_text')}
        </Button>
        <Button className={'h-9 text-base px-8'} solid onClick={() => props.resolve(false)}>
          {props.cancelText || t('btn_cancel_text')}
        </Button>
      </div>
    </Modal>
  )
}
