import { getHttpService, postHttpService } from '@/utils/http/service'
import { Hex } from 'viem'

export const gameService = {
  getGameDetail: getHttpService<
    GameInfo,
    {
      game_id?: number
      game_name?: string
    }
  >('/v1/game/detail'),
  startGame: postHttpService<
    {
      public_key: string
      free_times: number
      additional_times: number
      history_id: number
    },
    {
      game_id?: number
      game_name?: string
      additional?: boolean
    }
  >('/v1/game/start'),
  gameOver: postHttpService<
    GameOverInfo,
    {
      history_id: number
      score: number
      game_data: string
      prize_id?: number
    }
  >('/v1/game/stop'),
  gameReward: postHttpService<
    GameOverInfo,
    {
      history_id: number
      score: number
      game_data: string
      prize_id?: number
    }
  >('/v1/game/commit'),
  receiveConfirm: postHttpService<MintInfo, { nft_raffle_id: number }>('/v1/game/confirm'),
  rankingList: getHttpService<
    RankItem[],
    {
      ranking_type: RankingType
      game_name: string
    }
  >('/v1/user/ranking/list'),
  getUserRank: getHttpService<
    {
      ranking: number
      award_points: string
    },
    {
      ranking_type: RankingType
      game_name: string
    }
  >('/v1/user/ranking/user'),
}

export interface RankItem {
  user_name: string
  user_avatar: string
  ranking_value: number
  award_points: number
}

export interface GameInfo {
  preview_image: string
  show_name: string
  players: number
  highest_score: number
  free_times: number
  min_score: number
}

export enum NFTType {
  Super = 'super',
  Great = 'great',
}

export interface GameOverInfo {
  cheating: false
  nft_prize_id: number
  nft_prize_type: NFTType
  nft_prize_name: string
  nft_prize_fragment: boolean
  nft_prize_fragment_index: number
  nft_prize_image_url: string
  nft_raffle_id: number
  free_times: number
  additional_times: number
}

export interface MintInfo {
  signature: Hex
  program_id: string
  nft_name: string
  public_key: string
  message: Hex
  nft_type: NFTType
  contract_address: string
  receiving_address: string
  signature_type: 1 | 2 | 3
  payment_amount: number
  nonce: number
  order_id: number
  nft_image_url: string
  sign_param: {
    nft_item_index: number
    nft_item_content_url: string
    nft_collection_address: string
  }
}

export enum RankingType {
  SINGLE_GAME = 'SINGLE_GAME',
  GAME_TIMES = 'GAME_TIMES',
  NFT_FRAGMENT = 'NFT_FRAGMENT',
  NFT = 'NFT',
}
