import { useEffect, useRef, useState } from 'react'
import { gameBridge, GameEvent, WebEvent } from '@/utils/game-sdk'
import { GameOverModal, GameRewardModal } from '@/views/play/GameOverModal'
import { useModal } from '@/context/ModalContext'
import { useGameFreeDisabled } from '@/hooks/useGameFreeDisabled'
import { gameService } from '@/utils/http/services/game'
import { toastError } from '@/context/MessageContext'
import { useUserContext } from '@/context/UserContext'
import { usePlayContext } from '@/views/play/PlayContext'
import { HaveRewardModal } from '@/views/play/HaveRewardModal'
import { NoRewardModal } from '@/views/play/NoRewardModal'
import * as ecies from 'ecies-geth'
import { ChainType } from '@/context/ChainTypeContext'
import { EarnChanceModal } from '@/views/play/EarnChanceModal'
import useReference from '@/hooks/useReference'

export enum GameStatus {
  Loading,
  Loaded,
  Playing,
  GameOver,
}

function getDefaultScore() {
  return +(localStorage.getItem('default_score') || 0)
}

export default function useGame(name: string) {
  const ref = useRef<HTMLIFrameElement>(null)
  const [status, setStatus] = useState(GameStatus.Loading)
  const { freeDisabled, setFreeDisabled } = useGameFreeDisabled(name)
  const { isLogin, signIn, updateAdditionalTimes, refreshUserInfo } = useUserContext()
  const { updateFreeTimes, gameInfo } = usePlayContext()
  const { openModal } = useModal()
  const gameDataRef = useRef<{ historyId: number; publicKey: string } | null>(null)
  const [ticker, setTicker] = useState(0)
  const gameStatusRef = useReference(status)

  useEffect(() => {
    return () => {
      gameBridge.emit(WebEvent.GameDestroy, {})
    }
  }, [])

  const startGame: () => Promise<boolean> = async () => {
    if (!isLogin && !(await signIn(ChainType.Ton))) {
      return false
    }
    try {
      const data = await gameService.startGame({ game_name: name, additional: freeDisabled })
      gameDataRef.current = { historyId: data.history_id, publicKey: data.public_key }
      updateFreeTimes(data.free_times)
      updateAdditionalTimes(data.additional_times)
      setStatus(GameStatus.Playing)
      gameBridge.emit(WebEvent.GameReset, {})
      return true
    } catch (e: any) {
      if (e?.code === 100021) {
        //得利机会不足
        const rst = await openModal(EarnChanceModal, {})
        if (rst) {
          //如果在弹窗中购买了，刷新得利机会
          refreshUserInfo()
        }
      } else {
        toastError(e)
      }
      return false
    }
  }

  useEffect(() => {
    if (status === GameStatus.Loaded) {
      gameBridge.windowRef.window = ref.current?.contentWindow
      const offEvent = gameBridge.on(GameEvent.GameOver, async (data) => {
        const gameData = gameDataRef.current
        if (!gameData) return
        // data.data.score = getDefaultScore() || data.score
        // display game cover and play button
        // upload game data to server
        // get reward data and mint nft
        setStatus(GameStatus.GameOver)
        const result = await openModal(GameOverModal, {
          score: getDefaultScore() || data.score,
          history_id: gameData.historyId,
          data: await encodeData(data, gameData.publicKey),
        })
        if (result) {
          if (result.nft_prize_id > 0) {
            const res = await openModal(HaveRewardModal, { info: result, score: getDefaultScore() || data.score })
            if (res === 'reset') {
              startGame()
            }
          } else {
            openModal(NoRewardModal, {})
          }
        }
      })

      const offRewardEvent = gameBridge.on(GameEvent.GameReward, async (data) => {
        const gameData = gameDataRef.current
        if (!gameData) return
        // data.data.score = getDefaultScore() || data.score
        // display game cover and play button
        // upload game data to server
        // get reward data and mint nft
        // setStatus(GameStatus.Reward)
        const result = await openModal(GameRewardModal, {
          score: getDefaultScore() || data.score,
          history_id: gameData.historyId,
          data: await encodeData(data, gameData.publicKey),
        })
        if (result) {
          // debugger
          if (result.nft_prize_id > 0) {
            const res = await openModal(HaveRewardModal, { info: result, score: getDefaultScore() || data.score })
            if (res === 'reset') {
              startGame()
            }
          } else {
            openModal(NoRewardModal, {})
          }
        }
      })
      return () => {
        console.log('remove game over event')
        offEvent?.()
        offRewardEvent?.()
      }
    }
  }, [status === GameStatus.Loading])

  useEffect(() => {
    if (!ref.current) {
      const timer = setTimeout(() => {
        setTicker((t) => t + 1)
      }, 200)
      return () => {
        clearTimeout(timer)
      }
    }
    setStatus(GameStatus.Loading)
    const iframe = ref.current
    gameBridge.windowRef.window = iframe.contentWindow
    let offGameOverEventRef: { fn: (() => void) | undefined } = { fn: undefined }

    const timer = setInterval(() => {
      gameBridge.emit(WebEvent.RequestProgress, {})
      gameBridge.once(GameEvent.GameLoaded, (data) => {
        if (data.progress === 1 && gameStatusRef.current !== GameStatus.Loaded) {
          setStatus(GameStatus.Loaded)
          clearInterval(timer)
        }
      })
    }, 1000)
    const offLoadedEvent = gameBridge.once(GameEvent.GameLoaded, (data) => {
      if (data.progress === 1 && gameStatusRef.current !== GameStatus.Loaded) {
        setStatus(GameStatus.Loaded)
        clearInterval(timer)
      }
    })

    return () => {
      offLoadedEvent?.()
      offGameOverEventRef.fn?.()
      clearInterval(timer)
    }
  }, [name, ticker])
  return {
    status,
    setStatus,
    ref,
    freeDisabled,
    setFreeDisabled,
    startGame,
    gameInfo,
    refreshEvent: () => {
      console.log('refreshGameEvent')
      setTicker((t) => t + 1)
    },
  }
}

export async function encodeData(data: any, publicKey: string) {
  const dataStr = JSON.stringify(data)
  const publicKeyBuffer = Buffer.from(publicKey.replace('0x', ''), 'hex')
  const dataBuffer = Buffer.from(dataStr, 'utf8')
  const encypted = await ecies.encrypt(publicKeyBuffer, dataBuffer)
  return encypted.toString('hex')
}
