'use client'

import { createContext, useContext } from 'react'
import { GameInfo, gameService } from '@/utils/http/services/game'
import { useRequest, useUpdateEffect } from 'ahooks'
import R from '@/utils/http/request'
import { useUserContext } from '@/context/UserContext'

const PlayContext = createContext<{
  gameInfo: GameInfo
  refreshGameInfo: () => void
  updateFreeTimes: (times: number) => void
}>({
  gameInfo: {} as GameInfo,
  refreshGameInfo: () => {},
  updateFreeTimes: () => {},
})

export function PlayContextProvider(props: { children: any; gameInfo: GameInfo; name: string }) {
  const { data, refresh, mutate } = useRequest(R(gameService.getGameDetail, { game_name: props.name }))
  const { isLogin, userInfo } = useUserContext()

  useUpdateEffect(() => {
    refresh()
  }, [userInfo?.user_id])
  const updateFreeTimes = (times: number) => {
    mutate(data ? { ...data, free_times: times } : data)
  }
  return (
    <PlayContext.Provider
      value={{
        gameInfo: data || props.gameInfo,
        refreshGameInfo: refresh,
        updateFreeTimes,
      }}
    >
      {props.children}
    </PlayContext.Provider>
  )
}

export function usePlayContext() {
  return useContext(PlayContext)
}
