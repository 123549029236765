'use client'

import { createContext, ReactNode, useContext, useState } from 'react'

export enum ChainType {
  Solana,
  Ton,
}

const ChainTypeContext = createContext<{
  chainType: ChainType
  setChainType: (type: ChainType) => void
}>({
  chainType: ChainType.Solana,
  setChainType: () => {},
})

export function ChainTyeContextProvider(props: { children: ReactNode }) {
  const [type, setType] = useState<ChainType>(ChainType.Solana)
  return (
    <ChainTypeContext.Provider
      value={{
        chainType: type,
        setChainType: setType,
      }}
    >
      {props.children}
    </ChainTypeContext.Provider>
  )
}

export function useChainType() {
  return useContext(ChainTypeContext)
}
