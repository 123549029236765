import { ButtonHTMLAttributes, forwardRef, HTMLAttributes, ImgHTMLAttributes } from 'react'
import clsx from 'clsx'
import { XImage } from '@/views/common/XImage'
import { Images } from '@/utils/images'

export const Button = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement> & { loading?: boolean; solid?: boolean }
>((props, ref) => {
  const { loading, solid, ...restProps } = props
  return (
    <button
      {...restProps}
      className={clsx(
        `h-10   rounded-full text-sm disabled:pointer-events-none hover:opacity-70 transition-all duration-500 px-2`,
        solid
          ? 'bg-transparent text-white border border-greenA0EED6  disabled:border-[#8b8b8b] '
          : 'bg-greenA0EED6 text-black1A1D1F  disabled:bg-[#8b8b8b] ',
        props.className,
        props.loading ? 'pointer-events-none flex justify-center items-center' : '',
      )}
      ref={ref}
    >
      {props.loading && <XImage src={Images.ICON.LOADING_GRAY_SVG} className={'w-4 animate-spin mr-1'} />}
      {props.children}
    </button>
  )
})
