'use client'

import { getExpFromToken, getSignText } from '@/utils'
import { createContext, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { globalEvent, GlobalEventName } from '@/utils/event'
import { _getToken, clearToken, getToken, setTelToken, setToken } from '@/utils/http/token'
import dayjs from 'dayjs'
import { toastError } from '@/context/MessageContext'
import { UserInfo, userService } from '@/utils/http/services/user'
import { useSolWallet } from '@/context/SolWalletContext'
import { useWalletModal } from '@solana/wallet-adapter-react-ui'
import { bs58 } from '@coral-xyz/anchor/dist/cjs/utils/bytes'
import { storage } from '@/utils/storage'
import { isTest } from '@/utils/env'
import { SolanaChain, TonChain } from '@/utils/types'
import { useDebounceEffect, useRequest } from 'ahooks'
import { useTonConnectUI } from '@tonconnect/ui-react'
import { ChainType } from '@/context/ChainTypeContext'
import { isTMA, retrieveLaunchParams } from '@telegram-apps/sdk'
import { useTonConnect } from '@/hooks/ton/useTonConnect'
import { useSelectedLayoutSegment } from 'next/navigation'
import useReference from '@/hooks/useReference'
import { publicKeyToAddress } from '@/utils/ton/utils'
import { useModal } from '@/context/ModalContext'
import { ConfirmModal } from '@/views/common/ConfirmModal'
import { useTranslations } from 'next-intl'
import InviteModel from '@/views/InviteModal'
import GuideModal from '@/views/GuideModal'
import { RevertSceneType } from '@/hooks/useRevertScene'
import { removeAllCookie } from '@/utils/cookie'

export const UserContext = createContext<{
  isLogin: boolean
  signIn: (chainType?: ChainType) => Promise<boolean>
  userInfo?: UserInfo | null
  refreshUserInfo: () => Promise<UserInfo | null>
  signOut: () => void
  updateAdditionalTimes: (luckyTimes: number) => void
}>({
  isLogin: false,
  signIn: async () => false,
  signOut: () => {},
  userInfo: null,
  refreshUserInfo: async () => null,
  updateAdditionalTimes: () => {},
})

export function UserContextProvider(props: { children?: ReactNode; userInfo?: UserInfo | null }) {
  // const { chainType } = useChainType()
  // const wallet = useSolWallet()
  // const address = wallet.publicKey?.toBase58()
  const [userInfo, setUserInfo] = useState<UserInfo | null>(props.userInfo || null)
  const { signIn: solanaSignIn, signOut: solanaSignout } = useSolanaSignIn(userInfo)
  useTelSignIn()
  const refreshUserInfo = async () => {
    const token = getToken()
    if (!token) {
      setUserInfo(null)
      return null
    }
    try {
      const data = await userService.getUserInfo()
      setUserInfo(data)
      return data
    } catch (e) {
      toastError(e)
      return null
    }
  }
  const { signIn: tonSignIn, signOut: tonSignout } = useTonSignIn(userInfo, refreshUserInfo)

  useEffect(() => {
    const onTokenChange = async () => {
      console.log('onTokenChange execute refreshUserInfo')
      refreshUserInfo()
    }
    globalEvent.on(GlobalEventName.TokenChange, onTokenChange)
    return () => {
      globalEvent.off(GlobalEventName.TokenChange, onTokenChange)
    }
  }, [])

  const signIn = async (chainType: ChainType = ChainType.Solana) => {
    if (chainType === ChainType.Ton) {
      return await tonSignIn()
    } else {
      return await solanaSignIn()
    }
  }

  const signOut = () => {
    solanaSignout()
    tonSignout()
  }

  const updateAdditionalTimes = (additionalTimes: number) => {
    if (userInfo) {
      setUserInfo({
        ...userInfo,
        additional_times: additionalTimes,
      })
    }
  }

  const children = useMemo(() => {
    try {
      retrieveLaunchParams()
      return userInfo ? props.children : <></>
    } catch (error) {
      return props.children
    }
  }, [userInfo, props.children])

  return (
    <UserContext.Provider
      value={{
        isLogin: !!userInfo,
        signIn,
        signOut,
        userInfo,
        refreshUserInfo,
        updateAdditionalTimes,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

export function useUserContext() {
  return useContext(UserContext)
}

function useTelSignIn() {
  const { data } = useRequest(isTMA)
  const [ticker, setTicker] = useState(0)
  const { openModal } = useModal()
  useEffect(() => {
    if (data) {
      const launchParams = retrieveLaunchParams()
      console.log('launchParams', launchParams)
      if (!launchParams.initDataRaw) return
      const telegramId = launchParams.initData?.user?.id
      const storedTelegramId = storage.getItem('telegram_id')
      if (telegramId !== storedTelegramId) {
        if (getToken()) {
          storage.clear()
          removeAllCookie()
          storage.setItem('telegram_id', telegramId)
          globalEvent.emit(GlobalEventName.TokenChange, '')
          setTicker((t) => t + 1)
          return
        } else {
          storage.setItem('telegram_id', telegramId)
        }
      }
      if (!_getToken('tel_token')) {
        const inviteCode = storage.getItem('inviteCode') || ''
        const inviteRootCode = storage.getItem('inviteRootCode') || ''
        userService
          .loginWithTelegram({
            invite_code: inviteCode,
            telegram_data: launchParams.initDataRaw,
            invite_root_code: inviteRootCode,
          })
          .then(async (res) => {
            setTelToken(res.token)
            if (res.telegram_new_user) {
              // sessionStorage.setItem('hadOpenedInvite', 'true')
              sessionStorage.setItem('isTgNewUser', 'true')

              await openModal(GuideModal, {})
              // await openModal(InviteModel, {})
            }
          })
      } else {
        globalEvent.emit(GlobalEventName.TokenChange, '')
        if (!_getToken('token')) {
          userService.updateUserTelegram({ telegram_data: launchParams.initDataRaw })
        }
      }
    }
  }, [data, ticker])
}

function useSolanaSignIn(userInfo?: UserInfo | null) {
  const [loading, setLoading] = useState(false)
  const wallet = useSolWallet()
  const { setVisible } = useWalletModal()
  const resolveRef = useRef<null | ((val: boolean) => void)>(null)
  const account = wallet.publicKey?.toBase58()

  const login = async () => {
    if (!wallet.signMessage) return false
    setLoading(true)
    try {
      const { msg, nonce } = await getSignText()
      const encoder = new TextEncoder()
      const encodeMessage = encoder.encode(msg)
      const signature = await wallet.signMessage(encodeMessage)
      const base58String = bs58.encode(signature)
      const inviteCode = storage.getItem('inviteCode') || ''
      const inviteRootCode = storage.getItem('inviteRootCode') || ''
      const { token } = await userService.login({
        chain_id: isTest ? SolanaChain.Devnet : SolanaChain.Mainnet,
        address: wallet.publicKey?.toBase58() || '',
        public_key: wallet.publicKey?.toBase58() || '',
        signature: base58String,
        nonce,
        invite_code: inviteCode,
        invite_root_code: inviteRootCode,
      })
      setToken(token)
      return true
    } catch (e) {
      wallet.disconnect()
      toastError(e)
      return false
    } finally {
      setLoading(false)
    }
  }

  // useDebounceEffect(() => {
  //   if (!account) {
  //     clearToken()
  //   }
  //   if (account && !loading && (!userInfo || account !== userInfo?.user_address)) {
  //     clearToken()
  //     login().then((res) => {
  //       if (resolveRef.current) {
  //         resolveRef.current(res)
  //       }
  //     })
  //   }
  // }, [account])

  const signIn = async () => {
    setVisible(true)
    return await new Promise<boolean>((resolve) => {
      resolveRef.current = resolve
    })
  }
  const signOut = async () => {
    clearToken()
    wallet.disconnect()
  }
  return { signIn, loading, signOut }
}
function useTonSignIn(userInfo: UserInfo | null, refreshUserInfo: () => void) {
  const [ton] = useTonConnectUI()
  const { noneBounceableAddress: account } = useTonConnect()
  const [loading, setLoading] = useState(false)
  const getSignRef = useRef(false)
  const resolveRef = useRef<null | ((val: boolean) => void)>(null)
  const segment = useSelectedLayoutSegment()
  // const isPlayPage = segment === 'play'
  const userInfoRef = useReference(userInfo)
  const segmentRef = useReference(segment)
  const { openModal } = useModal()
  const t = useTranslations('common')

  useDebounceEffect(() => {
    // 针对重启应用触发自动连接后,判断是否需要断开连接
    // 1.如果用户未登录，断开钱包连接
    // 2.如果用户tg登陆了，但是未绑定钱包，或者连接的钱包地址和用户绑定的地址不一致，断开钱包连接
    // 3.用户重新启动应用，处于有token的登陆状态，但是发现当前自动连接的钱包地址和用户绑定的地址不一致，断开钱包连接
    if (!_getToken('token')) {
      if (ton.connected && account !== userInfo?.user_address) {
        console.log('do disconnect', _getToken('token'), ton.connected, account, userInfo)
        ton.disconnect()
      }
      return
    }
    if (!account || (userInfo && account !== userInfo?.user_address)) {
      console.log(`no account or account not equal userInfo.user_address`, `account=${account}`, 'userInfo', userInfo)
      clearToken()
      if (ton.connected) {
        ton.disconnect()
      }
    }
  }, [userInfo])

  useEffect(() => {
    ton.onStatusChange(async (wallet) => {
      if (getSignRef.current) return
      if (wallet?.connectItems?.tonProof && 'proof' in wallet.connectItems.tonProof) {
        getSignRef.current = true
        let operation: 'login' | 'bind' | 'none' | 'disconnect' = 'none'
        try {
          if (userInfoRef.current && userInfoRef.current.telegram_id > 0) {
            // 是tg用户

            const walletStatus = await userService.checkWallet({
              address: publicKeyToAddress(wallet.account.publicKey!, false),
              telegram_id: userInfoRef.current.telegram_id,
            })
            if (!userInfoRef.current.user_address) {
              //需要绑定钱包，如果连接全新地址，直接绑定，否则的话如果是在游戏界面，则警告放弃本次mint， 并用该钱包登录, 若确定放弃，则登录，否则断开连接
              if (walletStatus.registered) {
                if (
                  !(
                    (segmentRef.current === 'play' &&
                      storage.getItem('sceneData')?.type === RevertSceneType.RewardConnect) ||
                    (segmentRef.current === '' &&
                      storage.getItem('sceneData')?.type === RevertSceneType.ThreeFoldConnect)
                  ) ||
                  (await openModal(ConfirmModal, {
                    title: t('warning'),
                    description: t('connect_confirm'),
                  }))
                ) {
                  operation = 'login'
                } else {
                  operation = 'disconnect'
                }
              } else {
                operation = 'bind'
              }
            } else {
              //单纯的连接钱包, 如果连接到已绑定的钱包，直接返回。否则的话如果是在游戏界面，则警告放弃本次mint，并用该钱包登录， 若确定放弃，则登录，否则断开连接
              if (walletStatus.bind_current_telegram) {
                operation = 'none'
              } else {
                if (
                  !(
                    (segmentRef.current === 'play' &&
                      storage.getItem('sceneData')?.type === RevertSceneType.RewardConnect) ||
                    (segmentRef.current === '' &&
                      storage.getItem('sceneData')?.type === RevertSceneType.ThreeFoldConnect)
                  ) ||
                  (await openModal(ConfirmModal, {
                    title: t('warning'),
                    description: t('connect_confirm'),
                  }))
                ) {
                  operation = 'login'
                } else {
                  operation = 'disconnect'
                }
              }
            }
          } else {
            operation = 'login'
          }

          const proof = JSON.stringify({
            address: wallet.account.address,
            proof: {
              timestamp: wallet.connectItems.tonProof.proof.timestamp,
              payload: wallet.connectItems.tonProof.proof.payload,
              signature: wallet.connectItems.tonProof.proof.signature,
              domain: wallet.connectItems.tonProof.proof.domain.value,
              state_init: wallet.account.walletStateInit,
            },
          })

          if (operation === 'login') {
            const inviteCode = storage.getItem('inviteCode') || ''
            const inviteRootCode = storage.getItem('inviteRootCode') || ''
            const { token } = await userService.login({
              chain_id: isTest ? TonChain.Devnet : TonChain.Mainnet,
              invite_code: inviteCode,
              invite_root_code: inviteRootCode,
              proof,
            })
            //通知游戏页面关闭mint弹窗，
            console.log('notify close mint modal')
            globalEvent.emit(GlobalEventName.NewUserLogin)
            setToken(token)
          } else if (operation === 'bind') {
            await userService.bindWallet({
              chain_id: isTest ? TonChain.Devnet : TonChain.Mainnet,
              proof,
            })
            refreshUserInfo()
          } else if (operation === 'disconnect') {
            await ton.disconnect()
          }
          resolveRef.current?.(true)
        } catch (e) {
          toastError(e)
          resolveRef.current?.(false)
        }
      }
    })
  }, [ton])
  const signIn = async () => {
    console.log('sign In')
    getSignRef.current = false
    setLoading(true)
    if (ton.connected) {
      await ton.disconnect()
    }
    const payload = await userService.getWalletPayload()
    console.log('33333')
    ton.setConnectRequestParameters({ state: 'ready', value: { tonProof: payload } })
    ton.openModal()
    const rst = await new Promise<boolean>((resolve) => {
      resolveRef.current = resolve
      setLoading(false)
    })
    if (!rst) {
      ton.disconnect()
    }
    return rst
  }
  const signOut = async () => {
    clearToken()
    ton.disconnect()
  }
  return { signIn, loading, signOut }
}

function useToken() {
  const [token, setToken] = useState('')
  const [ticker, setTicker] = useState(0)
  useEffect(() => {
    const tokenChangeHandler = () => {
      setTicker((t) => t + 1)
    }
    globalEvent.on(GlobalEventName.TokenChange, tokenChangeHandler)
    return () => {
      globalEvent.off(GlobalEventName.TokenChange, tokenChangeHandler)
    }
  }, [])
  useEffect(() => {
    const token = getToken()
    if (token) {
      setToken(token)
      const exp = getExpFromToken(token)
      const now = dayjs().unix()
      if (exp - now < 86400) {
        const timer = setTimeout(
          () => {
            setToken('')
          },
          (exp - dayjs().unix()) * 1000,
        )
        return () => {
          clearTimeout(timer)
        }
      }
    } else {
      setToken('')
    }
  }, [ticker])
  return token
}
