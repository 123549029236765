import { XImage } from '@/views/common/XImage'
import clsx from 'clsx'
import { CSSProperties } from 'react'
import { Images } from '@/utils/images'

export default function Loading(props: { className?: string; size?: number }) {
  const size = props.size || 10
  return (
    <XImage
      className={clsx('w-2.5 h-2.5', props.className)}
      style={{ width: props.size, height: props.size }}
      src={Images.ICON.LOADING_SVG}
    />
  )
}

export function LoadingFrame(props: { size?: number; className?: string; style?: CSSProperties }) {
  return (
    <div className={clsx(props.className, 'w-full h-full flex justify-center items-center')} style={props.style}>
      <Loading size={props.size} />
    </div>
  )
}
