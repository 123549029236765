import { useTonConnectUI } from '@tonconnect/ui-react'
import { beginCell, toNano } from '@ton/core'
import dayjs from 'dayjs'
import { orderService, OrderType } from '@/utils/http/services/order'
import { toastError } from '@/context/MessageContext'
import { sleep } from '@/utils'
import useTonTokenBalance from '@/hooks/ton/useTonTokenBalance'
import { useTranslations } from 'next-intl'

export function useTonPurchase() {
  const [ton] = useTonConnectUI()
  const { getBalance } = useTonTokenBalance()
  const t = useTranslations('common')
  const sendTon = async (address: string, amount: number | string, payload: string) => {
    const tokenAmount = await getBalance()
    if (+amount + 0.01 > (tokenAmount.uiAmount || 0)) {
      throw new Error(t('insufficent'))
    }
    const body = beginCell().storeUint(0, 32).storeStringTail(payload).endCell()
    await ton.sendTransaction({
      validUntil: dayjs().add(5, 'minute').unix(),
      messages: [
        {
          address: address,
          amount: toNano(+amount + 0.01).toString(),
          payload: body.toBoc().toString('base64'),
        },
      ],
    })
    // return await waitForSeqNo()
  }
  const sendTon2 = async (address: string, amount: number | string, payload: string) => {
    const tokenAmount = await getBalance()
    if (+amount + 0.01 > (tokenAmount.uiAmount || 0)) {
      throw new Error(t('insufficent'))
    }
    const body = beginCell().storeUint(0, 32).storeStringTail(payload).endCell()
    try {
      await ton.sendTransaction({
        validUntil: dayjs().add(5, 'minute').unix(),
        messages: [
          {
            address: address,
            amount: toNano(+amount + 0.01).toString(),
            payload: body.toBoc().toString('base64'),
          },
        ],
      })
    } catch (e) {
      toastError(e)
      return false
    }
    return true
  }
  const buyAdditionalTimes = async (count: number) => {
    try {
      const order = await orderService.createOrder({ order_type: OrderType.ADDITIONAL_TIMES, count })
      await sendTon(order.receiving_address, order.amount, order.comment)
      return await waitForOrder(order.order_id)
    } catch (e) {
      toastError(e)
    }
    return false
  }
  const buyNewGift = async () => {
    try {
      const order = await orderService.createOrder({ order_type: OrderType.NEWBIE_GIFT, count: 1 })
      return await sendTon(order.receiving_address, order.amount, order.comment)
    } catch (e) {
      toastError(e)
    }
    return false
  }

  const buyThreefold = async (historyId: number) => {
    try {
      const order = await orderService.createOrder({
        order_type: OrderType.LUCKY_CODE_THREEFOLD,
        count: 1,
        payload: { history_id: historyId },
      })
      await sendTon(order.receiving_address, order.amount, order.comment)
      return await waitForOrder(order.order_id)
    } catch (e) {
      toastError(e)
    }
    return false
  }

  return { buyAdditionalTimes, buyNewGift, sendTon, sendTon2, buyThreefold }
}

async function waitForOrder(orderId: number | string) {
  while (true) {
    const res = await orderService.queryOrder({ order_id: orderId + '' })
    if (res.status === 'SUCCESS') {
      return true
    }
    if (res.status === 'FAILED') {
      return false
    }
    await sleep(3000)
  }
}
