'use client'

import { ModalComponent } from '@/context/ModalContext'
import Modal from '@/views/modal/Modal'
import { useTranslations } from 'next-intl'
import Loading from '@/views/common/Loading'
import { GameOverInfo, gameService } from '@/utils/http/services/game'
import { useRequest } from 'ahooks'
import R from '@/utils/http/request'
import { toastError } from '@/context/MessageContext'
import { Dialog } from '@/context/DialogContext'
import { getErrorMsg } from '@/utils'

export const GameOverModal: ModalComponent<GameOverInfo, { score: number; history_id: number; data: any }> = (
  props,
) => {
  const t = useTranslations('play')
  const { data, loading } = useRequest(
    R(gameService.gameOver, {
      score: props.score,
      history_id: props.history_id,
      game_data: props.data,
      prize_id: +(localStorage.getItem('prize_id') || 0) || undefined,
    }),
    {
      onSuccess: (data) => {
        props.resolve(data)
      },
      onError: (e) => {
        // toastError(e)
        Dialog.error(getErrorMsg(e))
        props.onClose()
      },
    },
  )
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      showClose
      className={'h-fit w-[80%] lg:w-90 pt-16 lg:px-13 pb-41'}
    >
      <h3 className={'uppercase text-[24px] lg:text-3xl text-white font-luck text-center'}>{t('calculating')}</h3>
      <Loading className={'mx-auto mt-20'} size={100} />
    </Modal>
  )
}

export const GameRewardModal: ModalComponent<GameOverInfo, { score: number; history_id: number; data: any }> = (
  props,
) => {
  const t = useTranslations('play')
  const { data, loading } = useRequest(
    R(gameService.gameReward, {
      score: props.score,
      history_id: props.history_id,
      game_data: props.data,
      prize_id: +(localStorage.getItem('prize_id') || 0) || undefined,
    }),
    {
      onSuccess: (data) => {
        // debugger
        props.resolve(data)
      },
      onError: (e) => {
        // toastError(e)
        Dialog.error(getErrorMsg(e))
        props.onClose()
      },
    },
  )
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      showClose
      className={'h-fit w-[80%] lg:w-90 pt-16 lg:px-13 pb-41'}
    >
      <h3 className={'uppercase text-[24px] lg:text-3xl text-white font-luck text-center'}>{t('calculating')}</h3>
      <Loading className={'mx-auto mt-20'} size={100} />
    </Modal>
  )
}
