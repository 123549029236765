'use client'

import { ModalComponent } from '@/context/ModalContext'
import Modal from '@/views/modal/Modal'
import { useTranslations } from 'next-intl'
import { Button } from '@/views/common/Button'
import { useRouter } from 'next-nprogress-bar'
import { XImage } from '@/views/common/XImage'
import { Images } from '@/utils/images'
import { useTonPurchase } from '@/hooks/ton/useTonPurchase'
import { useState } from 'react'
import { toastSuccess } from '@/context/MessageContext'
import { useTonConnectUI } from '@tonconnect/ui-react'
import { useUserContext } from '@/context/UserContext'
import { ChainType } from '@/context/ChainTypeContext'

export const EarnChanceModal: ModalComponent<boolean, {}> = (props) => {
  const t = useTranslations('play')
  const t1 = useTranslations('common')
  const router = useRouter()
  const { buyAdditionalTimes } = useTonPurchase()
  const [chance, setChance] = useState(1)
  const [loading, setLoading] = useState(false)
  const [ton] = useTonConnectUI()
  const { signIn } = useUserContext()
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      showClose
      className={'h-fit md:w-[504px] w-full md:pt-10 pt-[40px] md:px-12 px-4 pb-12 max-md:bottom-0 max-md:absolute'}
    >
      <h3 className={'uppercase text-3xl max-md:text-[22px] max-md:font-jua  text-white font-luck text-center'}>
        {t('chance_modal_title')}
      </h3>
      <div className={'flex justify-center items-center md:mt-6 mt-8 md:gap-x-4 gap-x-3'}>
        <div
          className={
            'w-49 h-60 rounded-[20px] bg-black16142E flex justify-end flex-col items-center pb-5 select-none max-w-[calc(100%-6px)]'
          }
        >
          <XImage src={Images.GAME.STAKE_PNG} className={'w-[96px]'} />
          <p className={'text-white text-center font-jua text-lg mt-5'}>{t('stake')}</p>
          <p className={'text-center text-gray808C9C text-sm mt-2'}>200$ = 1 {t('chance')}</p>
          <Button
            className={'w-41 mt-6 max-w-full'}
            onClick={async () => {
              router.push('/points')
            }}
          >
            {t('go_now')}
          </Button>
        </div>
        <div
          className={
            'w-49 h-60 rounded-[20px] bg-black16142E flex justify-end flex-col items-center pb-5 select-none max-w-[calc(100%-6px)]'
          }
        >
          <XImage src={Images.GAME.BUY_PNG} className={'w-[62px]'} />
          <p className={'text-white text-center font-jua text-lg mt-3.5'}>{t('purchase')}</p>
          <p className={'text-center text-gray808C9C text-sm mt-2 flex items-center justify-center'}>
            <XImage
              src={Images.GAME.MINUS_SVG}
              className={'w-2 h-2 block cursor-pointer'}
              onClick={() => setChance((prevState) => Math.max(prevState - 1, 1))}
            />
            <span className={'mx-2'}>
              {chance} {t('chance')}
              {chance > 1 ? 's' : ''}
            </span>
            <XImage
              src={Images.GAME.ADD_SVG}
              className={'w-2 h-2 cursor-pointer mr-2'}
              onClick={() => setChance((prevState) => prevState + 1)}
            />
            = {(chance * 0.3).toFixed(1)} TON
          </p>
          {ton.connected ? (
            <Button
              loading={loading}
              className={'w-41 mt-6 max-w-full'}
              onClick={async () => {
                setLoading(true)
                const rst = await buyAdditionalTimes(chance)
                if (rst) {
                  toastSuccess(t('purchase_success'))
                  props.resolve(true)
                }
                setLoading(false)
              }}
            >
              {t('buy_now')}
            </Button>
          ) : (
            <Button
              onClick={() => {
                signIn(ChainType.Ton)
              }}
              className={'w-41 mt-6 max-w-full'}
            >
              {t1('connect')}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  )
}
