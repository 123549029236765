import { FC, useEffect, useState } from 'react'
import Wrapper from './wrapper'
import RedPkgRain from './redPkgRain'
import VerifyCode from './VerifyCode'
import { TCheckCodeRes, redPkgRainService } from '@/utils/http/services/redPkgRain'
import Result from './Result'
import { useUserContext } from '@/context/UserContext'
import { encodeData } from '@/hooks/useGame'
import { useRequest } from 'ahooks'
import R from '@/utils/http/request'
import { toastError } from '@/context/MessageContext'
import NoPointsResult from './NoPointsResult'
import CountDown from './CountDown'
import { RevertSceneType, useRevertScene } from '@/hooks/useRevertScene'
import DisableBodyScroll from '@/views/common/DisableBodyScroll'
import { TInviteInfo } from '@/utils/http/services/personal-center'

let audio: any
const RedPkg: FC<{
  isShow: boolean
  onClose: () => void
  data?: TCheckCodeRes
  invitedInfo?: TInviteInfo | undefined
}> = ({ isShow, onClose, data, invitedInfo }) => {
  const [checkedCodeData, setCheckedCodeData] = useState<TCheckCodeRes>()
  const [points, setPoints] = useState(0)
  const { refreshUserInfo } = useUserContext()
  const [gameData, setGameData] = useState('')
  const [hadDoneRedPkgRain, setHadDoneRedPkgRain] = useState(false)
  const [isShowCountDown, setIsShowCountDown] = useState(true)

  useEffect(() => {
    setCheckedCodeData(data)
  }, [data])
  const {} = useRequest(
    R(redPkgRainService.submitRedPkgPoints, {
      history_id: checkedCodeData?.history_id,
      points: points,
      coin_symbol: 'Dego',
      game_data: gameData,
    }),
    {
      ready: !!checkedCodeData?.history_id && !!gameData,
      refreshDeps: [gameData, checkedCodeData?.history_id],
      onSuccess: () => {
        refreshUserInfo()
      },
      onError: (error) => {
        toastError(error)
      },
    },
  )

  const isShowVerifyCode = !checkedCodeData?.history_id
  // const isShowRedPkgRain = checkedCodeData?.history_id && !hadDoneRedPkgRain
  const isShowRedPkgRain = checkedCodeData?.history_id && !hadDoneRedPkgRain && !isShowCountDown
  const isShowResult = hadDoneRedPkgRain && !!points
  const isShowNoPointsResult = hadDoneRedPkgRain && !points

  useRevertScene(RevertSceneType.ThreeFoldConnect, (url, data) => {
    setPoints(data.countPoints)
    setCheckedCodeData(data.checkedCodeData)
    setHadDoneRedPkgRain(true)
  })

  const playMusic = () => {
    audio = new Audio('/mp3/rain.mp3')
    audio.volume = 1
    audio.muted = false
    audio.loop = true
    audio.play()
  }

  return (
    <>
      <Wrapper onClose={onClose} open={isShow}>
        <DisableBodyScroll />
        {isShowCountDown && checkedCodeData?.history_id && (
          <CountDown
            onCountDownFinished={() => {
              setIsShowCountDown(false)
              playMusic()
            }}
          />
        )}
        {/* {isShowVerifyCode && (
          <VerifyCode
            onClose={onClose}
            onVerifyCodeSuccess={(data) => {
              setCheckedCodeData(data)
              playMusic()
            }}
          />
        )} */}
        {isShowRedPkgRain && (
          <RedPkgRain
            onClick={() => {
              // playMusic()
            }}
            onResult={async (points) => {
              audio.pause()
              setPoints(points)
              setHadDoneRedPkgRain(true)
              if (checkedCodeData?.history_id) {
                const params = { history_id: checkedCodeData?.history_id, points: points, coin_symbol: 'Dego' }
                const res = await encodeData(params, checkedCodeData?.public_key || '')
                setGameData(res)
              }
            }}
          />
        )}
        {isShowResult && (
          <Result invitedInfo={invitedInfo} onClose={onClose} countPoints={points} checkedCodeData={checkedCodeData} />
        )}
        {isShowNoPointsResult && <NoPointsResult onClose={onClose} />}
      </Wrapper>
    </>
  )
}
export default RedPkg
