import { getHttpService, postHttpService } from '@/utils/http/service'

export const orderService = {
  createOrder: postHttpService<
    {
      order_id: string
      comment: string
      amount: string
      receiving_address: string
    },
    {
      order_type: OrderType
      count: number
      payload?: {
        history_id: number
      }
    }
  >('/v1/order/create'),
  queryOrder: getHttpService<
    {
      order_id: string
      comment: string
      amount: string
      purchase_count: number
      status: 'PENDING' | 'SUCCESS' | 'FAILED'
    },
    { order_id: string }
  >('/v1/order/query'),
  queryMint: getHttpService<{ status: 'PENDING' | 'SUCCESS' | 'FAILED' }, { order_id: number }>('/v1/order/query-mint'),
}

export enum OrderType {
  ADDITIONAL_TIMES = 'ADDITIONAL_TIMES',
  NEWBIE_GIFT = 'NEWBIE_GIFT',
  LUCKY_CODE_THREEFOLD = 'LUCKY_CODE_THREEFOLD',
}
