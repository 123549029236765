import { XImage } from '@/views/common/XImage'
import React, { FC, useState } from 'react'
import './index.css'
import { TCheckCodeRes } from '@/utils/http/services/redPkgRain'
import { useUserContext } from '@/context/UserContext'
import Btn from './Btn'
import { useTranslations } from 'next-intl'
import { BigNumber } from 'ethers'
import { useRequest } from 'ahooks'
import R from '@/utils/http/request'
import { personalCenterService, TInviteInfo } from '@/utils/http/services/personal-center'
import { inviteTgUrl, shareText } from '@/utils/constants'

const Result: FC<{
  countPoints: number
  checkedCodeData?: TCheckCodeRes
  onClose: () => void
  invitedInfo: TInviteInfo | undefined
}> = ({ countPoints, onClose, checkedCodeData, invitedInfo: _invitedInfo }) => {
  const t = useTranslations('redPkgRain')

  const { data: __invitedInfo, loading: invitedInfoLoading } = useRequest(
    R(personalCenterService.getInvitedInfo, {}),
    {},
  )

  const { userInfo } = useUserContext()

  const invitedInfo = _invitedInfo ||
    __invitedInfo || {
      invited_code: userInfo?.code,
      invited_root_code: userInfo?.root_code,
    }

  console.log('invitedInfo', invitedInfo)

  const degoValue = BigNumber.from(10000000000)
    .mul(BigNumber.from(countPoints || 0))
    .div(BigNumber.from(79))
    .mul(BigNumber.from(12))
    .div(BigNumber.from(100000))
  const tribleDegoValue = degoValue.mul(BigNumber.from(3))
  return (
    <div>
      <div className="text-[#A0EED6] text-[26px] border-t border-b border-[#2F7D81] border-solid flex justify-center items-center gap-x-[11px] py-[12px]">
        <XImage src="/images/redPkg/clock.png" width={35} />
        <span>{t('Time out')}</span>
      </div>
      <div className="flex justify-end mt-[64px] pr-[10px]">
        <XImage src="/images/icon/close_sm.svg" className="cursor-pointer" onClick={onClose} />
      </div>
      <div className="text-[#FFF] text-[16px] text-center uppercase">{t('Congratulations')}</div>
      <div className="flex justify-center gap-x-[3px] items-center mt-[7px]">
        <div className="flex flex-col items-center gap-y-[3px] mt-[107px]">
          <XImage src="/images/redPkg/congradutionimg1.png" className="w-[88px] " />
          <div className="text-[#A0EED6] text-[16px] mt-[16px]">
            {countPoints} <span className="text-[14px]">{t('Points')}</span>
          </div>

          <div className="text-[#808C9C] text-[14px] mt-[6px]">{t('Currently obtained')}</div>
        </div>
        <XImage src="/images/redPkg/arrow.png" width={42} className="ml-[3px] mr-[3px] mt-[-57px]" />
        <div className="flex flex-col items-center gap-y-[3px] mt-[-8px]">
          <XImage src="/images/redPkg/congradutionimg2.png" className="w-[105px]" />
          <div className="text-[#A0EED6] text-[16px] mt-[23px]">
            {countPoints * 3} <span className="text-[14px]">{t('Points')}</span>
          </div>

          <div className="text-[#808C9C] text-[14px] mt-[6px]">{t('be in line for')}</div>
        </div>
      </div>
      <div className="flex justify-center mt-[24px]">
        <Btn
          className="w-[80%]"
          disabled={!invitedInfo?.invited_code}
          loading={false}
          text={t('3x immediately')}
          onClick={() => {
            if (invitedInfo?.invited_code) {
              window.open(
                `${inviteTgUrl}?startapp=${invitedInfo?.invited_code || ''}${
                  invitedInfo?.invited_root_code ? '--' + invitedInfo?.invited_root_code : ''
                }&text=${encodeURIComponent(shareText)}`,
              )
            }
          }}
        />
      </div>

      <div className="text-[16px] text-[#CDCDCD] text-center mt-[40px]">{t('Invite two friends to get it!')}</div>
      <div className="pb-[30px]" />
    </div>
  )
}
export default Result
