import { FC, useEffect, useRef, useState } from 'react'
import { useUserContext } from '@/context/UserContext'
import { useInterval, useRequest } from 'ahooks'
import { TCheckCodeRes, redPkgRainService } from '@/utils/http/services/redPkgRain'
import R from '@/utils/http/request'
import { toastError } from '@/context/MessageContext'
import { useTranslations } from 'next-intl'

const CountDown: FC<{
  onCountDownFinished: () => void
}> = ({ onCountDownFinished }) => {
  const t = useTranslations('redPkgRain')
  const [timeCount, setTimeCount] = useState(3)

  const clearInterval = useInterval(() => {
    setTimeCount(timeCount - 1)
  }, 1000)
  useEffect(() => {
    if (timeCount === 0) {
      clearInterval()
      onCountDownFinished()
    }
  }, [timeCount])
  return (
    <div className="px-[26px] pt-[30vh]">
      <div className="text-[#fff] text-[20px] text-center">{t('Get ready for the reward rain!')}</div>
      <div className="text-[#fff] text-[50px] text-center mt-[28px]">{timeCount}</div>
    </div>
  )
}
export default CountDown
