import { useRef } from 'react'
import { useRequest } from 'ahooks'
import { isTMA } from '@telegram-apps/sdk'

export function useIsTMARef() {
  const isTMARef = useRef(false)
  useRequest(isTMA, {
    onSuccess: (data) => {
      isTMARef.current = data
    },
  })
  return isTMARef
}
