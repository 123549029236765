import { WalletContractV4 } from '@ton/ton'
import { Hex, hexToBytes } from 'viem'
import { isTest } from '@/utils/env'

export function publicKeyToAddress(publicKey: string, bounceable: boolean = false) {
  if (!publicKey) return ''
  const wallet = WalletContractV4.create({
    publicKey: Buffer.from(hexToBytes(('0x' + publicKey) as Hex)),
    workchain: 0,
  })
  return wallet.address.toString({ testOnly: isTest, bounceable })
}
