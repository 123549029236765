import { ModalComponent } from '@/context/ModalContext'
import { useTranslations } from 'next-intl'
import Modal from './modal/Modal'
import InviteList from './personal-center/task/InviteList'
import { useUserContext } from '@/context/UserContext'
import { useRequest } from 'ahooks'
import { personalCenterService } from '@/utils/http/services/personal-center'
import { XImage } from './common/XImage'
import Slider from 'react-slick'
import { useRef, useState } from 'react'
import RedPkg from './redPkg'
import { redPkgRainService } from '@/utils/http/services/redPkgRain'

const settings = {
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 500,
}
const GuideModal: ModalComponent<boolean, {}> = (props) => {
  const t = useTranslations('guideModel')
  const sliderRef = useRef<Slider>({} as Slider)
  const [currentPage, setCurrentPage] = useState(0)
  const goNext = () => {
    sliderRef?.current?.slickNext()
  }
  const goPrev = () => {
    sliderRef?.current?.slickPrev()
  }
  const [isShowRedPkg, setIsShowRedPkg] = useState(false)
  const onCloseRedPkg = () => {
    setIsShowRedPkg(false)
    props.onClose()
  }
  const { data: checkedCodeData } = useRequest(redPkgRainService.checkRedPkgCode, {})
  console.log('isShowRedPkg2', isShowRedPkg)
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      className={'w-full h-[100vh] rounded-none bg-[#070921]  px-[10px]  py-0 relative'}
    >
      {isShowRedPkg ? (
        <RedPkg data={checkedCodeData} isShow={isShowRedPkg} onClose={onCloseRedPkg} />
      ) : (
        <>
          <div className="absolute w-full flex justify-between bottom-[42px] left-0 px-[14%] z-50">
            {currentPage < 3 && (
              <>
                {currentPage > 0 ? (
                  <XImage
                    src="/images/common/left.png"
                    width={24}
                    onClick={() => {
                      goPrev()
                    }}
                  />
                ) : (
                  <div />
                )}
                {currentPage < 3 ? (
                  <XImage
                    src="/images/common/right.png"
                    width={24}
                    onClick={() => {
                      goNext()
                    }}
                  />
                ) : (
                  <div />
                )}
              </>
            )}
          </div>
          <Slider
            dots={currentPage < 3}
            swipe={currentPage < 3}
            ref={sliderRef}
            {...{ ...settings, slidesToShow: 1 }}
            beforeChange={(currentSlide: number, nextSlide: number) => setCurrentPage(nextSlide)}
          >
            <div className="h-[100vh] bg-[#070921] relative">
              <div className="flex justify-center pt-[25%]">
                <XImage src="/images/guide/guide1.png" className="w-[90%]" />
              </div>
              <div className="absolute bottom-[90px] text-[#fff] text-[20px] w-full flex justify-center items-center uppercase">
                {t('Play various games with MEME elements')}
              </div>
            </div>
            <div className="h-[100vh] bg-[#070921] relative">
              <div className="flex justify-center pt-[25%]">
                <XImage src="/images/guide/guide2.png" className="w-[90%]" />
              </div>
              <div className="absolute bottom-[90px] text-[#fff] text-[20px] w-full flex justify-center items-center uppercase">
                {t('Play games to earn MEME-NFTs for staking, yielding Points and diverse tokens')}
              </div>
            </div>
            <div className="h-[100vh] bg-[#070921] relative">
              <div className="flex justify-center pt-[25%]">
                <XImage src="/images/guide/guide3.png" className="w-[90%]" />
              </div>
              <div className="absolute bottom-[90px] text-[#fff] text-[20px] w-full flex justify-center items-center uppercase">
                {t('Stake tokens for Points, await airdrops')}
              </div>
            </div>
            <div className="h-[100vh] relative">
              <div className="flex flex-col items-center gap-y-[30px] pt-[50%]">
                <XImage src="/images/common/site.svg" className="w-[200px]" />
                <span className="uppercase text-[18px] text-[#fff] text-center w-full">
                  {t('Web3 MEME Gaming Platform')}
                </span>
              </div>
              <p className={'text-center mt-5'} dangerouslySetInnerHTML={{ __html: t.raw('Congratulations') }} />
              <div
                className="cursor-pointer absolute bottom-[90px] w-full bg-[#A0EED6] rounded-[22px] h-[44px] flex justify-center items-center  text-[#070921] text-[20px] font-bold uppercase"
                onClick={() => {
                  setIsShowRedPkg(true)
                }}
              >
                {t('Start Now')}
              </div>
            </div>
          </Slider>
        </>
      )}
    </Modal>
  )
}
export default GuideModal
